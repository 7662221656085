<template>
  <Head>
    <title>IPay Transaction | M-Changa Africa</title>
    <meta
      name="description"
      content="M-Changa Africa: Start your fundraiser in three quick steps."
    />
    <meta name="author" content="Mobi Changa" />
    <meta property="og:type" content="website" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta property="og:url" :content="this.baseURL + '/signup'" />
    <meta property="og:title" content="Sign Up | M-Changa Africa" />
    <meta
      property="og:description"
      content="M-Changa Africa: Start your fundraiser in three quick steps."
    />
    <meta property="fb:app_id" :content="this.facebookID" />
  </Head>
  <div>
    <section id="content">
      <div class="content-wrap py-0">
        <div class="section nobg nomargin">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="heading-block center">
                  <h2>Ipay Transaction</h2>
                </div>
                <div>
                  <p class="mb-1">Payment reference: {{ payment_reference }}</p>
                  <p class="mb-1">Status: {{ status }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
  
<script>
import { Head } from "@vueuse/head";
import Footer from "../components/Footer/index.vue";

export default {
  setup() {
    return {};
  },
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      payment_reference: this.$route.query.txncd,
      status: this.$route.query.status,
    };
  },

  mounted() {
    this.processPayment();
  },

  methods: {
    async processPayment() {
      console.log('picked details, processing...');
      let params = this.$route.query
      await fetch(
        process.env.VUE_APP_ROOT_API + `/service/donation/ipay-donation-process`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log('data-ed')
          console.log(data);
          let pagelink = process.env.VUE_APP_BASE_URL + `/fundraiser/` + this.$route.query.p2
          window.location.replace(pagelink);
        });
      console.log('ipay done');
    },
  },
};
</script>